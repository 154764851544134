import {
  ProductsMode,
  productsSharedService,
} from "@bees-grow-shared/services";

export function getCatalogUseCase() {
  const productService = productsSharedService();

  productService.setProductsState({
    mode: ProductsMode.CATALOG,
  });
  productService.getProducts({
    mode: ProductsMode.CATALOG,
  });
}
