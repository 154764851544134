import {
  ProductBoard,
  Container as ProductSelectionContainer,
} from "./components/productSelection/ProductSelection.styles";
import { Container as MissionsPageContainer } from "./pages/missionsPage/MissionsPage.styles";

export const LoadingContainer = MissionsPageContainer;

export const LoadingProductSelectionContainer = ProductSelectionContainer;

export const LoadingProductSelectionCard = ProductBoard;
