import { lazy, Suspense } from "react";

import { RouterWrapper } from "@bees-grow-shared/business-components";

import * as Styled from "./App.styles";
import { EnvProvider } from "./components/envProvider/EnvProvider";
import { EnvConfig } from "./components/envProvider/EnvProvider.types";
import { LoadingBehavior as LoadingBehaviorProductHeader } from "./components/productSelection/components/productSelectionHeader/ProductSelectionHeader";
import ResponsiveGridWrapper from "./components/productSelection/components/ResponsiveGridWrapper/ResponsiveGridWrapper";
import { LoadingBehavior as LoadingBehaviorTasksCarousel } from "./components/tasksCarousel/TasksCarousel";

const OptimizelyWrapper = lazy(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.OptimizelyWrapper,
  })),
);

const AnalyticsWrapper = lazy(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.AnalyticsWrapper,
  })),
);

const UserConfigWrapper = lazy(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.UserConfigWrapper,
  })),
);

const ThemeProvider = lazy(() =>
  import("@bees-grow-shared/theme").then((module) => ({
    default: module.ThemeProvider,
  })),
);

const IntlProvider = lazy(() =>
  import("@/i18n").then(({ IntlProvider }) => ({ default: IntlProvider })),
);

const MissionsPage = lazy(() => import("@/pages/missionsPage/MissionsPage"));

export default function App(props: Readonly<EnvConfig>) {
  return (
    <EnvProvider env={props}>
      <Suspense fallback={<LoadingBehavior />}>
        <ThemeProvider>
          <UserConfigWrapper>
            <OptimizelyWrapper sdkKey={props.OPTIMIZELY_KEY}>
              <AnalyticsWrapper segmentKey={props.SEGMENT_KEY}>
                <IntlProvider>
                  <RouterWrapper>
                    <MissionsPage />
                  </RouterWrapper>
                </IntlProvider>
              </AnalyticsWrapper>
            </OptimizelyWrapper>
          </UserConfigWrapper>
        </ThemeProvider>
      </Suspense>
    </EnvProvider>
  );
}

function LoadingBehavior() {
  return (
    <Styled.LoadingContainer>
      <LoadingBehaviorTasksCarousel cardsPerPage={4} />
      <Styled.LoadingProductSelectionContainer>
        <LoadingBehaviorProductHeader />
        <Styled.LoadingProductSelectionCard>
          <ResponsiveGridWrapper isLoading />
        </Styled.LoadingProductSelectionCard>
      </Styled.LoadingProductSelectionContainer>
    </Styled.LoadingContainer>
  );
}
