import { useIntl } from "react-intl";

import { ItemCard } from "@bees-grow-shared/business-components";
import { Select } from "@bees-grow-shared/components";
import {
  Combo as ComboDomain,
  isProduct,
  Product as ProductDomain,
} from "@bees-grow-shared/services";
import { Button } from "@hexa-ui/components";

import EmptyStateMediumIcon from "@/assets/EmptyStateMediumIcon";

import ProductSelectionHeader from "./components/productSelectionHeader/ProductSelectionHeader";
import ResponsiveGridWrapper from "./components/ResponsiveGridWrapper/ResponsiveGridWrapper";
import { useProductSelection } from "./hooks/useProductSelection";
import * as Styled from "./ProductSelection.styles";

export default function ProductSelection() {
  const {
    handleSelect,
    selectOptions,
    callUseCaseByMode,
    products,
    isInitialSearchStatusLoading,
    hasStatusError,
    selectedOption,
  } = useProductSelection();

  const renderProductItems = () => {
    return products.map((item: ProductDomain | ComboDomain) => {
      if (isProduct(item)) {
        return (
          <ItemCard.Product item={item} key={item.variants[0].platformId} />
        );
      }
      return null;
    });
  };

  if (isInitialSearchStatusLoading) {
    return <LoadingBehavior />;
  }

  return (
    <Styled.Container data-testid="product-selection">
      <ProductSelectionHeader />
      <Styled.ProductBoard data-testid="product-selection-board">
        <Styled.SearchProductInputContainer></Styled.SearchProductInputContainer>
        <Styled.SelectFilterContainer>
          <Select
            onChange={handleSelect}
            options={selectOptions}
            selectedOption={selectedOption}
          />
        </Styled.SelectFilterContainer>
        {hasStatusError ? (
          <ErrorBehavior onReload={callUseCaseByMode} />
        ) : (
          <ResponsiveGridWrapper>{renderProductItems()}</ResponsiveGridWrapper>
        )}
      </Styled.ProductBoard>
    </Styled.Container>
  );
}

function LoadingBehavior() {
  return (
    <Styled.Container data-testid="product-selection-loading">
      <Styled.ProductBoardTitleLoad data-testid="product-selection-header-loading" />
      <Styled.LoadingProductBoard data-testid="product-selection-board-loading">
        <ResponsiveGridWrapper isLoading />
      </Styled.LoadingProductBoard>
    </Styled.Container>
  );
}

function ErrorBehavior({ onReload }) {
  const { formatMessage } = useIntl();

  return (
    <Styled.ErrorContainer data-testid="product-selection-error">
      <EmptyStateMediumIcon />
      <Styled.ErrorParagraph>
        {formatMessage({ id: "ProductSelection.Error.DESCRIPTION" })}
      </Styled.ErrorParagraph>
      <Button variant="secondary" onClick={onReload}>
        {formatMessage({ id: "ProductSelection.Error.BUTTON" })}
      </Button>
    </Styled.ErrorContainer>
  );
}
