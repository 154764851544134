import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useIntl } from "react-intl";

import {
  GlobalMissionKeys,
  missionSharedService,
  MissionType,
  useTaskDescription,
  useTaskTitle,
} from "@bees-grow-shared/services";
import { sanitize } from "dompurify";

import { useDefaultAnalyticsData } from "@/components/hooks/useDefaultAnalyticsData";
import { getMissionDetailsUseCase } from "@/useCases/mission/getMissionDetails/GetMissionDetailsUseCase";

import analytics from "../../../../../config/typewriter";

interface UseTaskCardParams {
  missionKey: string | GlobalMissionKeys;
  title: string;
  description: string;
  script: string;
  type: MissionType;
  priority: number;
}

interface UseTaskCardProps {
  titleFormatted: string;
  descriptionFormatted: string;
  scriptFormatted: ReactElement[];
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  getMissionDetails: () => void;
}

export function useTaskCard({
  missionKey,
  title,
  description,
  type,
  script,
  priority,
}: UseTaskCardParams): UseTaskCardProps {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { formatMessage } = useIntl();
  const missionService = missionSharedService();
  const defaultData = useDefaultAnalyticsData();

  const getFormattedMessage = useCallback(
    (id: string) => {
      return formatMessage({ id });
    },
    [formatMessage],
  );

  const descriptionLocalTranslate = getFormattedMessage(
    "TasksCarousel.TaskCard.Local.Description.DEFAULT",
  );

  const getMissionDetails = useCallback(() => {
    missionService.setMissionDetails({
      action: getMissionDetailsUseCase({ missionKey }),
      missionKey,
    });
  }, [missionKey, missionService]);

  useEffect(() => {
    if (drawerOpen) {
      getMissionDetails();
      analytics.buttonClicked({
        task_name: title,
        task_priority: priority,
        ...defaultData,
        screen_name: "MISSIONS",
        button_label: null,
        button_name: "OPEN_MISSION_DETAILS",
        search_query: "",
        url: location.host,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen, getMissionDetails]);

  const globalTranslations = useMemo(() => {
    return {
      description: {
        [GlobalMissionKeys.MARKETPLACE]: getFormattedMessage(
          "TasksCarousel.TaskCard.Global.Description.MARKETPLACE",
        ),
        [GlobalMissionKeys.TOPLINE]: getFormattedMessage(
          "TasksCarousel.TaskCard.Global.Description.TOPLINE",
        ),
      },
      title: {
        [GlobalMissionKeys.MARKETPLACE]: getFormattedMessage(
          "TasksCarousel.TaskCard.Global.Title.MARKETPLACE",
        ),
        [GlobalMissionKeys.TOPLINE]: getFormattedMessage(
          "TasksCarousel.TaskCard.Global.Title.TOPLINE",
        ),
      },
      script: {
        [GlobalMissionKeys.MARKETPLACE]: getFormattedMessage(
          "TasksCarousel.TaskCard.Global.Script.MARKETPLACE",
        ),
        [GlobalMissionKeys.TOPLINE]: getFormattedMessage(
          "TasksCarousel.TaskCard.Global.Script.TOPLINE",
        ),
      },
    };
  }, [getFormattedMessage]);

  const { description: descriptionFormatted } = useTaskDescription({
    missionKey,
    description,
    type,
    defaultLocalTranslate: descriptionLocalTranslate,
    defaultGlobalTranslateMap: globalTranslations.description,
  });

  const { title: titleFormatted } = useTaskTitle({
    missionKey,
    title,
    type,
    defaultGlobalTranslateMap: globalTranslations.title,
  });

  const scriptFormatted: ReactElement[] = useMemo(() => {
    const translatedScript = globalTranslations.script[missionKey] ?? script;
    return ReactHtmlParser(sanitize(translatedScript));
  }, [missionKey, globalTranslations, script]);

  return {
    titleFormatted,
    descriptionFormatted,
    scriptFormatted,
    drawerOpen,
    setDrawerOpen,
    getMissionDetails,
  };
}
