import { useIntl } from "react-intl";

import {
  lastOrdersSharedService,
  useDefaultAnalyticsData,
} from "@bees-grow-shared/services";

import { SCREEN_NAME } from "@/config/constants";
import analytics from "@/config/typewriter";

import { ShowLastOrderButton } from "./ShowLastOrderButton.styles";

export default function LastOrderButton() {
  const { formatMessage } = useIntl();
  const defaultData = useDefaultAnalyticsData();
  const lastOrdersService = lastOrdersSharedService();

  const buttonText = formatMessage({
    id: "ProductSelection.LAST_ORDER_BUTTON",
  });

  const handleGoToFullTable = () => {
    analytics.buttonClicked({
      ...defaultData,
      button_label: buttonText,
      button_name: "last_orders_task_shortcut",
      screen_name: SCREEN_NAME,
      component_name: "LastOrderButton",
      url: null,
      search_query: null,
    });
    lastOrdersService.setLastOrdersState({
      showLastOrderModal: true,
    });
  };

  return (
    <ShowLastOrderButton variant="secondary" onClick={handleGoToFullTable}>
      {buttonText}
    </ShowLastOrderButton>
  );
}
