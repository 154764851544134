import MessageMap from "../i18n.d";

const es419: MessageMap = {
  TasksCarousel: {
    TITLE: "Tareas de llamadas",
    Empty: {
      DESCRIPTION: "No hay tareas asignadas a este POC.",
    },
    Error: {
      DESCRIPTION: "No es posible cargar las tareas de la llamada.",
      BUTTON: "Intentar de nuevo",
      GENERIC_ERROR: "Algo salió mal. Intenta de nuevo más tarde.",
    },
    TaskCard: {
      TOOLTIP_SPONSORED: "Patrocinada",
      Global: {
        Title: {
          TOPLINE: "Tarea de topline",
          MARKETPLACE: "Tarea de marketplace",
        },
        Description: {
          TOPLINE: "Recomendar productos basados en el pedido sugerido.",
          MARKETPLACE: "Fomentar la compra de productos del marketplace.",
        },
        Script: {
          TOPLINE:
            "Comprender la brecha entre el pedido actual y el pedido sugerido y recomendar productos para alcanzar los objetivos de ingresos.",
          MARKETPLACE:
            "Informar sobre los nuevos productos disponibles en el mercado y recomendarlos al PDV.",
        },
      },
      Local: {
        Description: {
          DEFAULT: "Tarea personalizada creada por los equipos de operaciones.",
        },
      },
    },
    TaskDrawer: {
      MARK_AS_DONE: "Marcar como hecho",
      MARK_AS_NOT_DONE: "Marcar como no hecho",
      Error: {
        RELOAD: "Recargar",
        GENERIC_ERROR:
          "No se puede cargar la información. Verifica tu conexión e intenta nuevamente.",
      },
    },
  },
  ProductSelection: {
    TITLE: "Productos",
    Error: {
      DESCRIPTION: "No se pueden cargar productos. Intente recargar la página.",
      BUTTON: "Recargar",
    },
    Modes: {
      SUGGESTED_ORDER: "Pedido Sugerido",
      PROMOTIONS: "Promociones",
      COMBOS: "Combos",
      CATALOG: "Catálogo",
    },
  },
};

export default es419;
