import {
  accountSharedService,
  agentCallSharedService,
  userConfigSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";
import { useUserMetadata } from "admin-portal-shared-services";

export interface DefaultAnalyticsData {
  org_id: string;
  country: string;
  email: string;
  call_id: string;
  vendor_account_id: string;
  vendor_id: string;
  customer_account_id: string;
  device_language: string;
  user_selected_language: string;
}
export function useDefaultAnalyticsData(): DefaultAnalyticsData {
  const accountService = accountSharedService();
  const { vendorId, customerAccountId, vendorAccountId } =
    useSharedUnit(accountService);

  const userConfigService = userConfigSharedService();
  const { orgId, country, userEmail } = useSharedUnit(userConfigService);
  const agentCallService = agentCallSharedService();
  const { callId } = useSharedUnit(agentCallService);
  const { data } = useUserMetadata();
  const language = data?.profile?.preferredLanguage;

  return {
    org_id: orgId,
    country,
    email: userEmail,
    call_id: callId,
    vendor_account_id: vendorAccountId,
    vendor_id: vendorId,
    customer_account_id: customerAccountId,
    device_language: window?.navigator?.language,
    user_selected_language: language,
  };
}
