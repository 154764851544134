import { useNavigate } from "react-router-dom";

import {
  cartSharedService,
  useDefaultAnalyticsData,
  useSharedUnit,
} from "@bees-grow-shared/services";

import { CHECKOUT_PATH, SCREEN_NAME } from "@/config/constants";
import analytics from "@/config/typewriter";

interface UseCheckoutButtonProps {
  cartQuantity: number;
  showCartQuantity: boolean;
  handleGoToCheckout: () => void;
}

export function useCheckoutButton(): UseCheckoutButtonProps {
  const navigate = useNavigate();
  const cartService = cartSharedService();
  const { cartItems } = useSharedUnit(cartService);
  const defaultData = useDefaultAnalyticsData();

  const handleGoToCheckout = () => {
    analytics.buttonClicked({
      ...defaultData,
      button_label: null,
      button_name: "cart_task_shortcut",
      url: null,
      screen_name: SCREEN_NAME,
      component_name: "CheckoutButton",
      search_query: null,
    });
    navigate(CHECKOUT_PATH);
  };
  const showCartQuantity =
    !!cartItems?.totalQuantity && cartItems?.totalQuantity > 0;

  return {
    cartQuantity: cartItems?.totalQuantity,
    showCartQuantity,
    handleGoToCheckout,
  };
}
