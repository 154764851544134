import { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";

import {
  ProductsMode,
  productsSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";

import { useDefaultAnalyticsData } from "@/components/hooks/useDefaultAnalyticsData";
import { getCatalogUseCase } from "@/useCases/products/getCatalog/GetCatalogUseCase";
import { getCombosUseCase } from "@/useCases/products/getCombos/GetCombosUseCase";
import { getPromotionsUseCase } from "@/useCases/products/getPromotions/GetPromotionsUseCase";
import { getSuggestedOrderUseCase } from "@/useCases/products/getSuggestedOrder/GetSuggestedOrderUseCase";

import analytics, { PromotionType } from "../../../config/typewriter";

export function useProductSelection() {
  const { formatMessage } = useIntl();
  const defaultData = useDefaultAnalyticsData();
  const productService = productsSharedService();
  const {
    mode,
    products,
    initialSearchStatus: {
      isLoading: isInitialSearchStatusLoading,
      error: hasInitialSearchStatusError,
    },
    pageSearchStatus: {
      error: hasPageSearchStatusError,
      done: isSearchStatusDone,
    },
  } = useSharedUnit(productService);

  const hasStatusError =
    hasInitialSearchStatusError || hasPageSearchStatusError;

  const selectOptions = useMemo(
    () => [
      {
        key: 0,
        label: formatMessage({
          id: "ProductSelection.Modes.SUGGESTED_ORDER",
        }),
        value: ProductsMode.SUGGESTED,
      },
      {
        key: 1,
        label: formatMessage({
          id: "ProductSelection.Modes.PROMOTIONS",
        }),
        value: ProductsMode.PROMOTIONS,
      },
      {
        key: 2,
        label: formatMessage({
          id: "ProductSelection.Modes.COMBOS",
        }),
        value: ProductsMode.COMBOS,
      },
      {
        key: 3,
        label: formatMessage({
          id: "ProductSelection.Modes.CATALOG",
        }),
        value: ProductsMode.CATALOG,
      },
    ],
    [formatMessage],
  );

  useEffect(() => {
    if (isSearchStatusDone) {
      analytics.productsLoaded({
        products: products.map((item) => {
          const product = item.variants[0];
          return {
            product_name: product.name,
            product_task: false,
            sku: product.sku,
            vendor_item_id: product.vendorId,
            platform_item_id: product.platformId,
            category: null,
            promotion_type: product.price?.promotion
              ? (Object.keys(product.price.promotion)[0] as PromotionType)
              : null,
            suggested_quantity: product.quantityInfo?.suggested,
            product_quantity: product.quantityInfo?.stock,
            variant_type: product.variantDescription,
          };
        }),
        ...defaultData,
        screen_name: "MISSIONS",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, isSearchStatusDone]);

  const selectedOption = useMemo(() => {
    return selectOptions.find((option) => option.value === mode);
  }, [selectOptions, mode]);

  const handleSelect = useCallback(
    (item) => {
      const formattedMode = item.value;
      productService.setProductsState({ mode: formattedMode });
    },
    [productService],
  );

  const callUseCaseByMode = useCallback(() => {
    switch (mode) {
      case ProductsMode.SUGGESTED:
        getSuggestedOrderUseCase();
        break;
      case ProductsMode.PROMOTIONS:
        getPromotionsUseCase();
        break;
      case ProductsMode.COMBOS:
        getCombosUseCase();
        break;
      case ProductsMode.CATALOG:
        getCatalogUseCase();
        break;
      default:
        break;
    }
  }, [mode]);

  useEffect(() => callUseCaseByMode(), [callUseCaseByMode]);

  return {
    handleSelect,
    callUseCaseByMode,
    selectOptions,
    products,
    isInitialSearchStatusLoading,
    hasStatusError,
    selectedOption,
  };
}
